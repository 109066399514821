<template>
  <div v-if="items.length != 0">
    <b-row v-if="items.isCoverImage">
      <b-col md="6">
        <div
          :class="[{ 'line-msg-img': items.is16To9 }]"
          class="preview-box"
          v-bind:style="{ 'background-image': 'url(' + items.coverImage + ')' }"
        >
          <img
            src="@/assets/images/icons/loading.svg"
            class="loading"
            alt="loading"
            v-if="isLoading"
          />
        </div>
      </b-col>
      <b-col>
        <UploadFile
          textFloat="Cover Image"
          placeholder="Please choose file"
          format="image"
          name="thumbnail"
          :text="'*Please upload only file .png, .jpg size ' + size"
          isRequired
          v-model="form.coverImage"
          :fileName="form.coverImage"
          v-on:onFileChange="onImageChange"
          v-on:delete="deleteImage"
          :v="$v.form.coverImage"
          :isValidate="$v.form.coverImage.$error"
          :checkRegRatio="items.is16To9"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <InputTextArea
          textFloat="Title"
          placeholder="Enter something..."
          type="text"
          name="title"
          v-model="form.title"
          rows="3"
          isRequired
          :v="$v.form.title"
          :isValidate="$v.form.title.$error"
          v-if="items.isTitle"
        />

        <label class="font-weight-bold main-label" v-if="items.isOption"
          >Options</label
        >

        <table class="w-100 table-line-msg" v-if="items.isOption">
          <tr class="text-center">
            <th>Text</th>
            <th>Label</th>
            <th>Sort order</th>
            <th></th>
          </tr>
          <tr
            v-for="(item, index) in form.options"
            :key="index"
            class="text-center"
          >
            <td>{{ item.text }}</td>
            <td>
              <b-form-input
                v-model="item.label"
                class="w-75 m-auto text-center"
                :class="[
                  { error: $v.form.options.$each.$iter[index].label.$error },
                ]"
                :disabled="true"
                v-if="!item.editable"
              />
              <b-form-input
                v-model="item.label"
                class="w-75 m-auto text-center"
                :class="[
                  { error: $v.form.options.$each.$iter[index].label.$error },
                ]"
                v-else
              />
            </td>
            <td>
              <b-form-input
                v-model="item.sortOrder"
                class="w-50 m-auto text-center"
                :class="[
                  {
                    error: $v.form.options.$each.$iter[index].sortOrder.$error,
                  },
                ]"
                @keypress="isNumber($event)"
              />
            </td>
            <td>
              <a
                :href="'/linemessage/' + item.intentId"
                v-if="item.intentId != 0"
              >
                <font-awesome-icon
                  icon="pencil-alt"
                  class="text-warning"
                  title="View"
                />
              </a>
            </td>
          </tr>
        </table>

        <span class="text-danger mt-3" v-if="$v.form.options.$each.$error"
          >These field is required</span
        >

        <b-row class="mt-5">
          <b-col md="6">
            <b-button
              to="/linemessage"
              class="btn-details-set"
              :disabled="isDisable"
              >CANCEL</b-button
            >
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
              @click="saveForm(0)"
              :disabled="isDisable"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
              @click="saveForm(1)"
              :disabled="isDisable"
            >
              Save & Exit
            </button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import InputTextArea from "@/components/inputs/InputTextArea";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ImageWithTable",
  components: {
    UploadFile,
    InputTextArea,
  },
  props: {
    items: {
      required: false,
      type: Array | Object,
    },
    isDisable: {
      required: false,
      type: Boolean,
    },
  },
  validations: {
    form: {
      coverImage: {
        required: requiredIf(function (value) {
          return this.items.isCoverImage == true;
        }),
      },
      title: {
        required: requiredIf(function (value) {
          return this.items.isTitle == true;
        }),
      },
      options: {
        $each: {
          label: {
            required: requiredIf(function (value) {
              return this.items.isOption == true;
            }),
          },
          sortOrder: {
            required: requiredIf(function (value) {
              return this.items.isOption == true;
            }),
          },
        },
      },
    },
  },
  created() {
    // if (this.id == 28) {
    //   this.LineMsgType = "PaymentAndOther";
    //   this.size = "only with 1040 x 1040 px";
    // } else {
    //   this.LineMsgType = "LineMsg";
    //   this.size = "with a maximun size of 1024 x 1024 px";
    // }

    if (this.items.is16To9 == 1) {
      this.size = "only with 16:9 image less than 10 MB";
      //this.LineMsgType = "";
    } else {
      this.size = "only with 1:1 image less than 1 MB";
    }

    this.form = this.items;
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      form: null,
      LineMsgType: "",
      size: "",
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    saveForm(flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.$emit("handleSave", this.form, flag);
    },
    onImageChange(img) {
      this.isLoading = true;
      //this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoading = false;
        this.isDisable = false;
        this.form.coverImage = this.images;
      };
    },
    deleteImage(img) {
      this.form.coverImage = null;
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        resize: true,
        base64: img,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
  },
};
</script>

<style scoped>
.table-line-msg tr th {
  background-color: #f47306;
  color: #ffffff;
  padding: 10px;
}

.table-line-msg tr td {
  background-color: #dcdcdc;
  padding: 10px;
}

.line-msg-img {
  padding-bottom: 56.25%;
}
</style>