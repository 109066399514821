<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase" v-if="items">{{items.headerName}}</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-bind:class="$isLoading ? 'd-none': 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4" v-if="items">
            <ImageWithTable
              v-if="menuType == 1"
              :items="items"
              @handleSave="saveForm"
              :isDisable="disabled"
            />
            <!-- <TextAreaOnly v-else-if="menuType == 2" :items="items" /> -->
            <TableOnly
              v-else-if="menuType == 2"
              :items="items"
              @handleSave="saveForm"
              :isDisable="disabled"
            />
          </b-col>
        </b-row>
      </b-container>
    </form>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ImageWithTable from "./components/ImageWithTable";
import TableOnly from "./components/TableOnly";
import TextAreaOnly from "./components/TextAreaOnly";
import DataList from "@/components/linemessage/datalist.js";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import VueCookies from "vue-cookies";

export default {
  name: "LineMsgDetails",
  components: {
    ImageWithTable,
    TableOnly,
    TextAreaOnly,
    DataList,
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      id: this.$route.params.id,
      DataList,
      dataList: [],
      index: 0,
      menuType: 0,
      intentTypeId: 0,
      title: "",
      items: null,
      modalMessage: "",
      disabled: false,
      url: "",
      cookie: "",
      existId: "",
    };
  },
  created: async function () {
    this.$isLoading = true;
    this.setUrl();
    this.$isLoading = false;
  },
  methods: {
    setUrl() {
      setTimeout(async () => {
        this.cookie = VueCookies.get("IsImageList");

        if (this.id == 36 || this.id == 37) {
          this.url = `${this.$baseUrl}/api/message/imgesList/${this.id}`;
        } else {
          this.url = `${this.$baseUrl}/api/message/${this.id}`;
        }

        await this.getData();
      }, 500);
    },
    getData: async function () {
      let resData = await this.$callApi(
        "get",
        this.url,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.items = resData.detail;

        if (this.id == 36 || this.id == 37) {
          this.menuType = 2;
        } else {
          this.menuType = 1;
        }
      }
    },
    saveForm: async function (data, flag) {
      this.disabled = true;
      var url = "";

      if (this.id == 36 || this.id == 37) {
        url = `${this.$baseUrl}/api/message/imgesList/${this.id}`;
      } else {
        url = `${this.$baseUrl}/api/message/${this.id}`;
      }

      let resData = await this.$callApi("post", url, null, this.$headers, data);
      this.modalMessage = resData.message;

      if (resData.result == 1) {
        this.$refs.modalAlert.show();

        if (flag == 1) {
          setTimeout(this.routeToUserList, 2000);
        } else {
          this.$isLoading = true;
          this.setUrl();
          this.$isLoading = false;
        }
      } else {
        this.$refs.modalAlertError.show();
      }
      this.disabled = false;
    },
    routeToUserList() {
      this.$router.push("/linemessage");
    },
  },
};
</script>