<template>
  <div>
    <div class="mb-3 text-right">
      <button type="button" class="btn btn-main-2 button" @click="addEditData(true,0)">ADD</button>
    </div>

    <b-table striped hover :items="form.images" :fields="fields">
      <template v-slot:cell(imageUrl)="data">
        <div v-bind:style="{ 'background-image': `url(${data.item.imageUrl})`  }" class="img-table-only"></div>
      </template>
      <template v-slot:cell(sortOrder)="data">
        <b-form-input
          v-model="data.item.sortOrder"
          :class="['inline-input',{ error: $v.form.images.$each.$iter[data.index].sortOrder.$error }]"
          @keypress="isNumber($event)"
        />
      </template>
      <template v-slot:cell(id)="data">
        <b-button
          variant="link"
          class="px-1 py-0"
          @click="addEditData(false,data.item.id,data.item,data.index)"
        >
          <font-awesome-icon icon="pencil-alt" class="text-warning" title="View" />
        </b-button>
        <b-button variant="link" class="px-1 py-0">
          <font-awesome-icon
            icon="trash-alt"
            class="text-danger"
            @click="deleteItem(data.item.id,data.index)"
          />
        </b-button>
      </template>
    </b-table>

    <span class="text-danger mt-3" v-if="form.images.length == 0">Please enter at least one data</span>

    <b-row class="mt-5">
      <b-col md="6">
        <b-button to="/linemessage" class="btn-details-set" :disabled="isDisable">CANCEL</b-button>
      </b-col>
      <b-col md="6" class="text-sm-right">
        <button
          type="button"
          class="btn btn-success btn-details-set ml-md-2 text-uppercase"
          @click="saveForm(0)"
          :disabled="isDisable"
        >Save</button>
        <button
          type="button"
          class="btn btn-success btn-details-set ml-md-2 text-uppercase"
          @click="saveForm(1)"
          :disabled="isDisable"
        >Save & Exit</button>
      </b-col>
    </b-row>

    <b-modal
      id="addEditData"
      ref="addEditData"
      title="BootstrapVue"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <div class="modal-header border-bottom-0">
        <h2 class="mb-1 font-weight-bold">Add/Edit Data</h2>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('addEditData')"
        >×</button>
      </div>
      <div class="modal-body">
        <b-row>
          <b-col md="6">
            <div
              class="preview-box"
              v-bind:style="{ 'background-image': 'url('+modalData.imageUrl+')' }"
            >
              <img
                src="@/assets/images/icons/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoading"
              />
            </div>
          </b-col>
          <b-col md="6">
            <UploadFile
              textFloat="Thumbnail"
              placeholder="Please choose file"
              format="image"
              name="mobileapplogo"
              text="*Please upload only file .png, .jpg only with 966 x 966 px and less than 10 MB"
              :fileName="modalData.imageUrl"
              v-model="modalData.imageUrl"
              v-on:onFileChange="onImageChange"
              v-on:delete="deleteImage"
              :v="$v.modalData.imageUrl"
              :isValidate="$v.modalData.imageUrl.$error"
              isRequired
            />

            <InputText
              class="mb-4"
              textFloat="Sort Order"
              placeholder="Sort Order"
              type="text"
              name="sortby"
              isRequired
              v-model="modalData.sortOrder"
              :isValidate="$v.modalData.sortOrder.$error"
              :v="$v.modalData.sortOrder"
            />
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col md="6">
            <b-button
              @click="$bvModal.hide('addEditData')"
              class="btn-details-set ml-md-2"
              :disabled="isDisable"
            >CANCEL</b-button>
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              v-if="id != 0"
              type="button"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
              @click="saveData"
              :disabled="isDisable"
            >Save</button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import InputText from "@/components/inputs/InputText";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "TableOnly",
  components: {
    UploadFile,
    InputText,
  },
  props: {
    items: {
      required: false,
      type: Array | Object,
    },
    isDisable: {
      required: false,
      type: Boolean,
    },
  },
  validations: {
    form: {
      images: {
        $each: {
          sortOrder: {
            required,
          },
        },
      },
    },
    modalData: {
      sortOrder: { required },
      imageUrl: { required },
    },
  },
  created() {
    this.form = this.items;
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      form: null,
      isAdd: false,
      index: 0,
      coverImage: "",
      images: "",
      modalData: {
        id: 0,
        sortOrder: "",
        imageUrl: "",
      },
      fields: [
        {
          key: "imageUrl",
          label: "Thumbnail",
        },
        {
          key: "sortOrder",
          label: "Sort Order",
        },
        {
          key: "id",
          label: "Action",
        },
      ],
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    saveForm(flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      if (this.form.images.length == 0) return;

      this.$emit("handleSave", this.form, flag);
    },
    deleteItem(id, index) {
      if (id != 0) this.form.removeList.push(id);
      this.form.images.splice(index, 1);
    },
    addEditData(isAdd, id, data, index) {
      this.isAdd = isAdd;
      if (this.isAdd) {
        this.clearData();
      } else {
        this.index = index;
        this.modalData.id = data.id;
        this.modalData.sortOrder = data.sortOrder;
        this.modalData.imageUrl = data.imageUrl;
      }
      this.$refs["addEditData"].show();
    },
    saveData() {
      this.$v.modalData.$touch();
      if (this.$v.modalData.$error) return;

      this.$refs["addEditData"].hide();
      this.$v.modalData.$reset();

      if (this.isAdd) {
        let data = {
          id: this.modalData.id,
          sortOrder: this.modalData.sortOrder,
          imageUrl: this.modalData.imageUrl,
        };
        this.form.images.push(data);
      } else {
        var existData = this.form.images[this.index];

        existData.id = this.modalData.id;
        existData.sortOrder = this.modalData.sortOrder;
        existData.imageUrl = this.modalData.imageUrl;
      }
    },
    clearData() {
      this.modalData.name = "";
      this.modalData.sortOrder = 0;
      this.modalData.imageUrl = "";
    },
    onImageChange(img) {
      this.isLoading = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoading = false;
        this.modalData.imageUrl = this.images;
      };
    },
    deleteImage(img) {
      this.modalData.imageUrl = null;
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
  },
};
</script>

<style scoped>
.img-table-only {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
}
</style>