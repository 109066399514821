<template>
  <div>
    <b-row>
      <b-col>
        <InputTextArea
          textFloat="Title"
          placeholder="Enter something..."
          type="text"
          name="title"
          v-model="items.title"
          rows="3"
          isRequired
        />

        <!-- Button Cancel  Exit -->
        <b-row class="mt-5">
          <b-col md="6">
            <b-button to="/linemessage" class="btn-details-set" :disabled="isDisable">CANCEL</b-button>
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
              @click="saveForm(0)"
              :disabled="isDisable"
            >Save</button>
            <button
              type="button"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
              @click="saveForm(1)"
              :disabled="isDisable"
            >Save & Exit</button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";

export default {
  name: "TextAreaOnly",
  components: {
    InputTextArea,
  },
  props: {
    items: {
      required: false,
      type: Array | Object,
    },
  },
  data() {
    return {
      isDisable: false,
    };
  },
  methods: {
    saveForm(event) {
      this.$emit("onDataChange", event);
    },
  },
};
</script>