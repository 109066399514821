var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items.length != 0)?_c('div',[(_vm.items.isCoverImage)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"preview-box",class:[{ 'line-msg-img': _vm.items.is16To9 }],style:({ 'background-image': 'url(' + _vm.items.coverImage + ')' })},[(_vm.isLoading)?_c('img',{staticClass:"loading",attrs:{"src":require("@/assets/images/icons/loading.svg"),"alt":"loading"}}):_vm._e()])]),_c('b-col',[_c('UploadFile',{attrs:{"textFloat":"Cover Image","placeholder":"Please choose file","format":"image","name":"thumbnail","text":'*Please upload only file .png, .jpg size ' + _vm.size,"isRequired":"","fileName":_vm.form.coverImage,"v":_vm.$v.form.coverImage,"isValidate":_vm.$v.form.coverImage.$error,"checkRegRatio":_vm.items.is16To9},on:{"onFileChange":_vm.onImageChange,"delete":_vm.deleteImage},model:{value:(_vm.form.coverImage),callback:function ($$v) {_vm.$set(_vm.form, "coverImage", $$v)},expression:"form.coverImage"}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',[(_vm.items.isTitle)?_c('InputTextArea',{attrs:{"textFloat":"Title","placeholder":"Enter something...","type":"text","name":"title","rows":"3","isRequired":"","v":_vm.$v.form.title,"isValidate":_vm.$v.form.title.$error},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}):_vm._e(),(_vm.items.isOption)?_c('label',{staticClass:"font-weight-bold main-label"},[_vm._v("Options")]):_vm._e(),(_vm.items.isOption)?_c('table',{staticClass:"w-100 table-line-msg"},[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("Text")]),_c('th',[_vm._v("Label")]),_c('th',[_vm._v("Sort order")]),_c('th')]),_vm._l((_vm.form.options),function(item,index){return _c('tr',{key:index,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(item.text))]),_c('td',[(!item.editable)?_c('b-form-input',{staticClass:"w-75 m-auto text-center",class:[
                { error: _vm.$v.form.options.$each.$iter[index].label.$error },
              ],attrs:{"disabled":true},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}}):_c('b-form-input',{staticClass:"w-75 m-auto text-center",class:[
                { error: _vm.$v.form.options.$each.$iter[index].label.$error },
              ],model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1),_c('td',[_c('b-form-input',{staticClass:"w-50 m-auto text-center",class:[
                {
                  error: _vm.$v.form.options.$each.$iter[index].sortOrder.$error,
                },
              ],on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(item.sortOrder),callback:function ($$v) {_vm.$set(item, "sortOrder", $$v)},expression:"item.sortOrder"}})],1),_c('td',[(item.intentId != 0)?_c('a',{attrs:{"href":'/linemessage/' + item.intentId}},[_c('font-awesome-icon',{staticClass:"text-warning",attrs:{"icon":"pencil-alt","title":"View"}})],1):_vm._e()])])})],2):_vm._e(),(_vm.$v.form.options.$each.$error)?_c('span',{staticClass:"text-danger mt-3"},[_vm._v("These field is required")]):_vm._e(),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{staticClass:"btn-details-set",attrs:{"to":"/linemessage","disabled":_vm.isDisable}},[_vm._v("CANCEL")])],1),_c('b-col',{staticClass:"text-sm-right",attrs:{"md":"6"}},[_c('button',{staticClass:"btn btn-success btn-details-set ml-md-2 text-uppercase",attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.saveForm(0)}}},[_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-success btn-details-set ml-md-2 text-uppercase",attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.saveForm(1)}}},[_vm._v(" Save & Exit ")])])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }